import { isLocalhost } from '../../utils/utils';
import includes from 'lodash/fp/includes';

import {
    TENANT_RIO_BRAZIL_PROD,
    TENANT_RIO_EU_PROD,
    TENANT_RIO_EU_TEST,
    TENANT_RIO_SOUTHKOREA_PROD,
} from '../../configuration/tokenHandling/tokenSlice';
import { featureToggles } from '../../configuration/setup/featureToggles';

const UPSELLING_WIDGET_LOCAL_URL = 'http://localhost:3060';
const UPSELLING_WIDGET_PROD_URL = 'https://upselling.marketplace.rio.cloud/';

const ROUTEPLANNING_WIDGET_LOCAL_URL = 'http://localhost:3020';
const ROUTEPLANNING_WIDGET_PROD_URL = 'https://route-planning.rio.cloud/';

const SMART_ROUTEPLANNING_SETTINGS_WIDGET_LOCAL_URL = 'http://localhost:3020';
const SMART_ROUTEPLANNING_SETTINGS_WIDGET_PROD_URL = 'https://route-widget.smart-route.rio.cloud/';

const RANGE_WIDGET_LOCAL_URL = 'http://localhost:3020';
const RANGE_WIDGET_PROD_URL = 'https://range-widget.smart-route.rio.cloud/';

const CHARGING_STATION_SEARCH_WIDGET_LOCAL_URL = 'http://localhost:3002/#/search';
const CHARGING_STATION_SEARCH_WIDGET_PROD_URL = 'https://charging-station-widget.smart-route.rio.cloud/search';

const CHARGING_STATION_DETAILS_WIDGET_LOCAL_URL = 'http://localhost:3002/#/details';
const CHARGING_STATION_DETAILS_WIDGET_PROD_URL = 'https://charging-station-widget.smart-route.rio.cloud/details';

const SIMPLE_PAY_POIS_WIDGET_PROD_URL = 'https://livemonitor-widget-poi-filter.jupiter.rio.cloud/';

const HISTORY_WIDGET_LOCAL_URL = 'http://localhost:3006/#eventhistory';
const HISTORY_WIDGET_PROD_URL = 'https://asset-history.rio.cloud/#widget';

const POI_DETAILS_WIDGET_LOCAL_URL = 'http://localhost:3010/#poiDetails';
const POI_DETAILS_WIDGET_PROD_URL = 'https://pois.rio.cloud/#poiDetails';

const GEOFENCE_DETAILS_WIDGET_LOCAL_URL = 'http://localhost:3012/#geofenceDetails';
const GEOFENCE_DETAILS_WIDGET_PROD_URL = 'https://widget.geofencing.rio.cloud/#geofenceDetails';

const ACTIVITY_WIDGET_LOCAL_URL = 'http://localhost:3040/#widget';
const ACTIVITY_WIDGET_PROD_URL = 'https://drivingtime.rio.cloud/#widget';

const TOURS_WIDGET_LOCAL_URL = 'http://localhost:3010/#/widget';
const TOURS_WIDGET_PROD_URL = 'https://tour.smit-research.rio.cloud/#/widget';

const CHAT_WIDGET_LOCAL_URL = 'http://localhost:3001/#/chatDetails?hideChatNavigation=true';
const CHAT_WIDGET_PROD_URL =
    'https://order-communication-chat.smit-research.rio.cloud/#/chatDetails?hideChatNavigation=true';

const REMOTE_DOWNLOAD_WIDGET_PROD_URL = 'https://widget.remotedownload.rio.cloud/index.html';

const ATTACHMENTS_LOCAL_URL = 'http://localhost:3050/';
const ATTACHMENTS_PROD_URL = 'https://attachments.rio.cloud/';

export type Widget = {
    name: string;
    url: string;
    initialDelay?: number;
    isWidgetUrl?: (origin: string) => boolean;
    isAllowedTenant?: (origin: string) => boolean;
    supportInfo?: () => string;
    geoUrl?: (assetId: string) => string;
};

const shouldUseLocalUrl = () => featureToggles.enforcedLocalWidget || isLocalhost();

/**
 * The upselling widget is used in several places where we want to do upselling for the product geo.
 */
export const UPSELLING_WIDGET = {
    name: 'Upselling Widget',
    url: isLocalhost() ? UPSELLING_WIDGET_LOCAL_URL : UPSELLING_WIDGET_PROD_URL,
    supportInfo: () => `${UPSELLING_WIDGET.name} - Team Marketplace \n${UPSELLING_WIDGET.url}`,
    geoUrl: (assetId: string) =>
        assetId
            ? `${UPSELLING_WIDGET.url}?productId=rio-sku00000047&resourceId=${assetId}&noBorder=true`
            : `${UPSELLING_WIDGET.url}?productId=rio-sku00000047&noBorder=true`,
};

/**
 * The route planning widget is a tab in the main sidebar on the left.
 * We added an initial delay to boost loading the essential parts of the livemonitor.
 * It is possible to hide route planning for certain tenants.
 * The route planning context is owned by Team Transport Two.
 *
 * NOTE: When opening route planning, the livemonitor switches context to route planning.
 */
export const ROUTEPLANNING_WIDGET = {
    name: 'RoutePlanningWidget',
    initialDelay: 5_000,
    url: shouldUseLocalUrl() ? ROUTEPLANNING_WIDGET_LOCAL_URL : ROUTEPLANNING_WIDGET_PROD_URL,
    isWidgetUrl: (origin: string) =>
        ROUTEPLANNING_WIDGET_PROD_URL.includes(origin) ||
        ROUTEPLANNING_WIDGET_LOCAL_URL.includes(origin) ||
        origin === 'http://localhost:3000', // Note, it has to be set to the local dev server port for the MessageHandler test to work since they are issued from the tests with the local dev server port
    isAllowedTenant: (tenant: string) => !includes(tenant)([]),
};

/**
 * The smart route planning settings widget is a tab in main navigation.
 * The context is owned by Team Smart Route Planning (MAN).
 *
 * NOTE: This is experimental for now until the smart route planning is better integrated with the livemonitor
 */
export const SMART_ROUTEPLANNING_SETTINGS_WIDGET = {
    name: 'SmartRoutePlanningSettingsWidget',
    url: shouldUseLocalUrl()
        ? SMART_ROUTEPLANNING_SETTINGS_WIDGET_LOCAL_URL
        : SMART_ROUTEPLANNING_SETTINGS_WIDGET_PROD_URL,
    isWidgetUrl: (origin: string) =>
        SMART_ROUTEPLANNING_SETTINGS_WIDGET_PROD_URL.includes(origin) ||
        SMART_ROUTEPLANNING_SETTINGS_WIDGET_LOCAL_URL.includes(origin),
};

/**
 * The smart route vehicle range widget is a tab in the asset details.
 * The context is owned by Team Smart Route (MAN).
 */
export const RANGE_WIDGET = {
    name: 'SmartRoutePlanningDetailsWidget',
    url: shouldUseLocalUrl() ? RANGE_WIDGET_LOCAL_URL : RANGE_WIDGET_PROD_URL,
    isWidgetUrl: (origin: string) => RANGE_WIDGET_PROD_URL.includes(origin) || RANGE_WIDGET_LOCAL_URL.includes(origin),
};

/**
 * The charging stations search widget is a tab in main navigation.
 * The context is owned by Team Smart Route Planning (MAN).
 */
export const CHARGING_STATION_SEARCH_WIDGET = {
    name: 'ChargingStationsSearchWidget',
    url: shouldUseLocalUrl() ? CHARGING_STATION_SEARCH_WIDGET_LOCAL_URL : CHARGING_STATION_SEARCH_WIDGET_PROD_URL,
    isWidgetUrl: (origin: string) =>
        CHARGING_STATION_SEARCH_WIDGET_PROD_URL.includes(origin) ||
        CHARGING_STATION_SEARCH_WIDGET_LOCAL_URL.includes(origin),
};

/**
 * The charging station details widget is a tab in the asset details.
 * The context is owned by Team Smart Route Planning (MAN).
 */
export const CHARGING_STATION_DETAILS_WIDGET = {
    name: 'ChargingStationsDetailsWidget',
    url: shouldUseLocalUrl() ? CHARGING_STATION_DETAILS_WIDGET_LOCAL_URL : CHARGING_STATION_DETAILS_WIDGET_PROD_URL,
    isWidgetUrl: (origin: string) =>
        CHARGING_STATION_DETAILS_WIDGET_PROD_URL.includes(origin) ||
        CHARGING_STATION_DETAILS_WIDGET_LOCAL_URL.includes(origin),
    allowedFeatures: 'clipboard-read; clipboard-write',
};

/**
 * The history widget is a tab in the asset details.
 * The asset history context is owned by Team Transport Two.
 *
 * NOTE: When opening the asset history, the livemonitor switches context to asset history.
 */
export const HISTORY_WIDGET = {
    name: 'EventHistorySummaryWidget',
    url: shouldUseLocalUrl() ? HISTORY_WIDGET_LOCAL_URL : HISTORY_WIDGET_PROD_URL,
    isWidgetUrl: (origin: string) =>
        HISTORY_WIDGET_PROD_URL.includes(origin) ||
        HISTORY_WIDGET_LOCAL_URL.includes(origin) ||
        origin === 'http://localhost:3000', // Note, it has to be set to the local dev server port for the MessageHandler test to work since they are issued from the tests with the local dev server port
};

/**
 * The poi details widget provides the details for selected pois.
 *
 * The poi context is owned by Team Transport Two.
 */
export const POI_DETAILS_WIDGET = {
    name: 'PoisDetailsWidget',
    url: shouldUseLocalUrl() ? POI_DETAILS_WIDGET_LOCAL_URL : POI_DETAILS_WIDGET_PROD_URL,
    isWidgetUrl: (origin: string) =>
        POI_DETAILS_WIDGET_PROD_URL.includes(origin) || POI_DETAILS_WIDGET_LOCAL_URL.includes(origin),
};

/**
 * The geofence details widget provides the details for selected geofences.
 *
 * The geofence context is owned by Team Transport Two.
 */
export const GEOFENCE_DETAILS_WIDGET = {
    name: 'GeofenceDetailsWidget',
    url: shouldUseLocalUrl() ? GEOFENCE_DETAILS_WIDGET_LOCAL_URL : GEOFENCE_DETAILS_WIDGET_PROD_URL,
    isWidgetUrl: (origin: string) =>
        GEOFENCE_DETAILS_WIDGET_PROD_URL.includes(origin) || GEOFENCE_DETAILS_WIDGET_LOCAL_URL.includes(origin),
};

/**
 * The activity widget provides detailed information for drivers and vehicles.
 * It is possible to hide the activity tab for certain tenants.
 *
 * The timed context is owned by Team Transport Two.
 */
export const ACTIVITY_WIDGET = {
    name: 'ActivityWidget',
    url: shouldUseLocalUrl() ? ACTIVITY_WIDGET_LOCAL_URL : ACTIVITY_WIDGET_PROD_URL,
    isWidgetUrl: (origin: string) =>
        ACTIVITY_WIDGET_PROD_URL.includes(origin) || ACTIVITY_WIDGET_LOCAL_URL.includes(origin),
    isAllowedTenant: (tenant: string) => !includes(tenant)([TENANT_RIO_BRAZIL_PROD, TENANT_RIO_SOUTHKOREA_PROD]),
};

/**
 * Experimental tours widget.
 *
 * The smit-research context is owned by Team Transport One.
 */
export const TOURS_WIDGET = {
    name: 'ToursWidget',
    url: shouldUseLocalUrl() ? TOURS_WIDGET_LOCAL_URL : TOURS_WIDGET_PROD_URL,
    isWidgetUrl: (origin: string) => TOURS_WIDGET_PROD_URL.includes(origin) || TOURS_WIDGET_LOCAL_URL.includes(origin),
    isAllowedTenant: (tenant: string) => includes(tenant)([TENANT_RIO_EU_PROD, TENANT_RIO_EU_TEST]),
};

/**
 * The attachment widget provides detailed information for trailers.
 *
 * The trailer context is owned by Team Transport Two.
 */
export const ATTACHMENTS_WIDGET = {
    name: 'AttachmentWidget',
    url: shouldUseLocalUrl() ? ATTACHMENTS_LOCAL_URL : ATTACHMENTS_PROD_URL,
    isWidgetUrl: (origin: string) => ATTACHMENTS_PROD_URL.includes(origin) || ATTACHMENTS_LOCAL_URL.includes(origin),
    isAllowedTenant: (tenant: string) => !includes(tenant)([TENANT_RIO_BRAZIL_PROD, TENANT_RIO_SOUTHKOREA_PROD]),
};

/**
 * The remote download widget is part of RIO Compliance and allows downloading tachograph files from livemonitor.
 * It is possible to hide the remote download widget for certain tenants.
 *
 * The compliance context is owned by Team Compliant.
 */
export const REMOTE_DOWNLOAD_WIDGET = {
    name: 'RemoteDownloadWidget',
    url: REMOTE_DOWNLOAD_WIDGET_PROD_URL,
    isWidgetUrl: (origin: string) => REMOTE_DOWNLOAD_WIDGET_PROD_URL.includes(origin),
    isAllowedTenant: (tenant: string) => !includes(tenant)([TENANT_RIO_BRAZIL_PROD, TENANT_RIO_SOUTHKOREA_PROD]),
};

/**
 * The widget for filtering pois for SimplePay
 *
 * The widget context is owned by Team MAN SimplePay.
 */
export const SIMPLE_PAY_POIS_WIDGET = {
    name: 'SimplePayPoisWidget',
    url: SIMPLE_PAY_POIS_WIDGET_PROD_URL,
    isWidgetUrl: (origin: string) => SIMPLE_PAY_POIS_WIDGET_PROD_URL.includes(origin),
    isAllowedTenant: (tenant: string) => !includes(tenant)([TENANT_RIO_BRAZIL_PROD, TENANT_RIO_SOUTHKOREA_PROD]),
    allowedFeatures: 'clipboard-read; clipboard-write',
};

/**
 * The widget for SimplePay poi details
 *
 * The widget context is owned by Team MAN SimplePay.
 * Note: The "url" is provided by the marker data and does not need to be set here.
 */
export const SIMPLE_PAY_POI_DETAILS_WIDGET = {
    name: 'SimplePayPoiDetailsWidget',
    isAllowedTenant: (tenant: string) => !includes(tenant)([TENANT_RIO_BRAZIL_PROD, TENANT_RIO_SOUTHKOREA_PROD]),
    allowedFeatures: 'clipboard-read; clipboard-write',
};
