import { createSelector } from '@reduxjs/toolkit';

import get from 'lodash/fp/get';
import union from 'lodash/fp/union';
import isEmpty from 'lodash/fp/isEmpty';
import flow from 'lodash/fp/flow';
import maxBy from 'lodash/fp/maxBy';
import minBy from 'lodash/fp/minBy';
import intersectionWith from 'lodash/fp/intersectionWith';

import {
    getTransformedData,
    getSelectedAssetGroupIds,
    getSelectedAssetIds,
    getActiveAsset,
    getWorkshopPois,
    getCustomerPois,
    getGroups,
    getGeofences,
} from '../app/selectors';
import {
    getActivityFilter,
    filterByActivity,
    getAssetFilter,
    filterByAssetTypesOrDriver,
    getNotificationFilter,
    filterByNotifications,
} from '../table/tableSelectors';
import { hasActiveSummaryFilter } from '../summary/summarySelectors';
import { defaultMapState, MapContext } from './mapSlice';
import type { RootState } from '../../configuration/setup/store';
import type { Asset, Coordinates, CoordinatesShort, Geofence, Group, Poi, TransformedData } from '../../services/types';

export type MapPoi = {
    id: string;
    type: string;
    name: string;
    address: string;
    lat: number;
    lng: number;
};

export type MapGeofence = {
    id: string;
    type: string;
    name: string;
    active: boolean;
    radius?: number;
    points: CoordinatesShort[];
    center: (CoordinatesShort & { address: string | null }) | undefined;
    lat: number;
    lng: number;
};

export const getHereSettings = (state: RootState) => state.app.map.hereSettings;
export const getHereSettingsError = (state: RootState) => state.app.map.hereSettingsError;

export const hasMapLoaded = (state: RootState) => state.app.map.mapLoaded;

export const getCenter = (state: RootState) => state.app.map.center;
export const getZoom = (state: RootState) => state.app.map.zoom;
export const getBoundingBox = (state: RootState) => state.app.map.boundingBox || defaultMapState.boundingBox;
export const getBoundingBoxReadOnly = (state: RootState) => state.app.map.boundingBoxReadOnly;

export const getLockOnAsset = (state: RootState) => state.app.map.lockOnAsset;

export const getCurrentMapContext = (state: RootState) => state.app.map.currentContext;
export const getMapContextMap = (state: RootState) => state.app.map.contextMap;

export const getMapContainerSizeTimestamp = (state: RootState) => state.app.map.mapContainerSizeTimestamp;

export const getContextMapForCurrentContext = createSelector(
    getMapContextMap,
    getCurrentMapContext,
    (contextMap, currentMapContext) => contextMap[currentMapContext]
);

export const getRoutePlanningRoute = flow(getMapContextMap, get(`${MapContext.MAP_CONTEXT_ROUTEPLANNING}.route`));

export const getContextRoute = flow(getContextMapForCurrentContext, get('route'));
export const getContextMarkers = flow(getContextMapForCurrentContext, get('markers'));
export const getContextShapes = flow(getContextMapForCurrentContext, get('shapes'));
export const getContextChargingStations = flow(getContextMapForCurrentContext, get('chargingStations'));
export const getContextSimplePayPois = flow(getContextMapForCurrentContext, get('simplePayPois'));

export const getGlobalSearchMarker = (state: RootState) => state.app.map.globalSearchMarker;

export const getRoutePlanningMarkers = flow(getMapContextMap, get(`${MapContext.MAP_CONTEXT_ROUTEPLANNING}.markers`));

export const getMapType = (state: RootState) => state.app.map.mapType;
export const getMapLayers = (state: RootState) => state.app.map.mapLayers;

export const getMapRendering = (state: RootState) => state.app.map.mapRendering;

export const getShowCluster = (state: RootState) => state.app.map.showCluster;
export const getShowWorkshopPois = (state: RootState) => state.app.map.showWorkshopPois;
export const getShowCustomerPois = (state: RootState) => state.app.map.showCustomerPois;
export const getShowGeofences = (state: RootState) => state.app.map.showGeofences;
export const getShowChargingStations = (state: RootState) => state.app.map.showChargingStations;
export const getShowSimplePayPois = (state: RootState) => state.app.map.showSimplePayPois;

export const getActiveAdditionalLayers = createSelector(
    getShowWorkshopPois,
    getShowCustomerPois,
    getShowGeofences,
    getShowChargingStations,
    getShowSimplePayPois,
    (hasWorkshopPois, hasCustomerPois, hasGeofences, hasChargingStations, hasSimplePayPois) => {
        const activeLayers = [];
        if (hasWorkshopPois) {
            activeLayers.push('WORKSHOP_POIS');
        }
        if (hasCustomerPois) {
            activeLayers.push('CUSTOMER_POIS');
        }
        if (hasGeofences) {
            activeLayers.push('GEOFENCE');
        }
        if (hasChargingStations) {
            activeLayers.push('CHARGING_STATIONS');
        }
        if (hasSimplePayPois) {
            activeLayers.push('SIMPLE_PAY_POIS');
        }
        return activeLayers;
    }
);

export const getClusterAssetIds = (state: RootState) => state.app.map.selectedClusterAssetIds;
export const getClusterChargingStationIds = (state: RootState) => state.app.map.selectedClusterChargingStationIds;
export const getClusterSimplePayPoiIds = (state: RootState) => state.app.map.selectedClusterSimplePayPoiIds;

const getValidSelectedAssetIds = (selectedAssetIds: string[], assets: TransformedData[]) =>
    intersectionWith((selectedAssetId: string, asset: Asset) => selectedAssetId === asset.vehicleId)(selectedAssetIds)(
        assets
    );

const getValidSelectedGroupIds = (selectedAssetGroupIds: string[], groups: Group[]) =>
    intersectionWith((selectedGroupId: string, group: Group) => selectedGroupId === group.id)(selectedAssetGroupIds)(
        groups
    );

export const hasActiveMapFilter = createSelector(
    hasActiveSummaryFilter,
    getSelectedAssetIds,
    getSelectedAssetGroupIds,
    (hasActiveFilter, selectedAssetIds, selectedGroupIds) => {
        return hasActiveFilter || selectedAssetIds.length > 1 || !isEmpty(selectedGroupIds);
    }
);

const withActiveAsset = (assetList: TransformedData[], activeAsset: TransformedData | undefined) => {
    if (!activeAsset || assetList.find(asset => asset.vehicleId === activeAsset.vehicleId)) {
        return assetList;
    }
    return [activeAsset, ...assetList];
};

export const getFilteredMapAssetData = createSelector(
    getTransformedData,
    getActivityFilter,
    getAssetFilter,
    getNotificationFilter,
    getActiveAsset,
    getSelectedAssetIds,
    getSelectedAssetGroupIds,
    getGroups,
    (
        transformedData,
        activityFilter,
        assetTypeFilter,
        notificationFilter,
        activeAsset,
        selectedAssetIds,
        selectedAssetGroupIds,
        groups
    ) => {
        const validSelectedAssetIds = getValidSelectedAssetIds(selectedAssetIds, transformedData);
        const validSelectedGroupIds = getValidSelectedGroupIds(selectedAssetGroupIds, groups);

        const hasAssetsSelected = validSelectedAssetIds.length !== 0;
        const hasGroupsSelected = !isEmpty(validSelectedGroupIds);

        // Only when groups or multiple assets are selected filter accordingly.
        const filteredByAssetIds = transformedData.filter(({ vehicleId }: TransformedData) =>
            validSelectedAssetIds.includes(vehicleId)
        );
        const filteredByAssetGroupIds = transformedData.filter(({ groupIds = [] }: TransformedData) =>
            validSelectedGroupIds.find(selectedGroupId => groupIds.includes(selectedGroupId))
        );

        let filteredAssetsBySelection: TransformedData[];

        // Has assets and groups selected, unite both filtered asset lists as the user may have selected a group and asset
        if (hasAssetsSelected && hasGroupsSelected) {
            filteredAssetsBySelection = union(filteredByAssetIds, filteredByAssetGroupIds);
        }
        // Has only assets and no groups selected, take the filtered assets by id
        else if (hasAssetsSelected && !hasGroupsSelected) {
            filteredAssetsBySelection = filteredByAssetIds;
        }
        // Has no assets but groups selected, take the filtered assets by group
        else if (!hasAssetsSelected && hasGroupsSelected) {
            filteredAssetsBySelection = filteredByAssetGroupIds;
        }
        // Has nothing selected, take the unfiltered list of assets
        else {
            filteredAssetsBySelection = transformedData;
        }

        // Filter list of remaining assets by activity, type and notifications
        const filteredByActivity = filterByActivity(filteredAssetsBySelection, activityFilter);
        const filteredByAssetType = filterByAssetTypesOrDriver(filteredByActivity, assetTypeFilter);
        const filteredByNotifications = filterByNotifications(filteredByAssetType, notificationFilter);
        const result = filteredByNotifications;

        // Always render the active Asset regardless of any filter
        return withActiveAsset(result as TransformedData[], activeAsset);
    }
);

const mapPoiData = (pois: Poi[] = [], type: string): MapPoi[] =>
    pois
        .filter(({ latitude, longitude }) => latitude && longitude)
        .map(({ latitude, longitude, ...rest }) => ({
            ...rest,
            type,
            lat: Number(latitude),
            lng: Number(longitude),
        }));

const convertCoordinates = ({ latitude, longitude }: Coordinates): CoordinatesShort => ({
    lat: Number(latitude),
    lng: Number(longitude),
});

const getLat = (item: CoordinatesShort) => item.lat;
const getLng = (item: CoordinatesShort) => item.lng;

export const getPolygonCenter = (points: CoordinatesShort[]): CoordinatesShort => {
    const maxLat = maxBy(getLat)(points)?.lat;
    const maxLng = maxBy(getLng)(points)?.lng;

    const minLat = minBy(getLat)(points)?.lat;
    const minLng = minBy(getLng)(points)?.lng;

    const latDiff = maxLat !== undefined && minLat !== undefined ? maxLat - minLat : 0;
    const lngDiff = maxLng !== undefined && minLng !== undefined ? maxLng - minLng : 0;

    const lat = maxLat !== undefined ? maxLat - latDiff / 2 : 0;
    const lng = maxLng !== undefined ? maxLng - lngDiff / 2 : 0;

    return {
        lat,
        lng,
    };
};

const mapGeofenceData = (geofences: Geofence[] = []): MapGeofence[] =>
    geofences
        .filter(({ center, points = [] }) => center || points.length > 0)
        .map(({ center, points, ...rest }) => {
            const convertedPoints = points?.map(convertCoordinates) || [];
            const convertedCenter = center && {
                ...convertCoordinates(center),
                address: center.address,
            };

            const markerCenter = convertedCenter || getPolygonCenter(convertedPoints);

            return {
                ...rest,
                points: convertedPoints,
                center: convertedCenter,
                // type is optional but is used for the geofence icon in the tree
                type: points ? 'polygon' : 'sphere',
                ...markerCenter,
            };
        });

export const getMapWorkshopPois = createSelector(getWorkshopPois, pois => mapPoiData(pois, 'workshop'));

export const getMapCustomerPois = createSelector(getCustomerPois, pois => mapPoiData(pois, 'pushpin'));

export const getMapGeofences = createSelector(getGeofences, geofences => mapGeofenceData(geofences));
