import { useAppSelector } from '../../configuration/setup/hooks';
import {
    areOnboardingTipsShown,
    getActiveAsset,
    getActiveAssetId,
    getActiveChargingStation,
    getActiveChargingStationId,
    getActiveSimplePayPoiId,
    getActiveSimplePayPoi,
    getActiveGeofenceId,
    getActivePoiId,
    getAddresses,
    getOnboardingState,
    getSelectedAssetGroupIds,
    getSelectedAssetIds,
    getSelectedDriverIds,
    getTransformedData,
    getVisibleWidgetIds,
    isFetchAddressesRequested,
    getOverallGeoBookingState,
} from './selectors';

export const useActiveAsset = () => useAppSelector(getActiveAsset);
export const useActiveAssetId = () => useAppSelector(getActiveAssetId);

export const useActiveChargingStationId = () => useAppSelector(getActiveChargingStationId);
export const useActiveChargingStation = () => useAppSelector(getActiveChargingStation);

export const useActiveSimplePayPoiId = () => useAppSelector(getActiveSimplePayPoiId);
export const useActiveSimplePayPoi = () => useAppSelector(getActiveSimplePayPoi);

export const useSelectedAssetIds = () => useAppSelector(getSelectedAssetIds);
export const useSelectedAssetGroupIds = () => useAppSelector(getSelectedAssetGroupIds);

export const useOnboardingState = () => useAppSelector(getOnboardingState);
export const useShowOnboardingTips = () => useAppSelector(areOnboardingTipsShown);

export const useTransformedData = () => useAppSelector(getTransformedData);

export const useAddressesRequested = () => useAppSelector(isFetchAddressesRequested);
export const useAddresses = () => useAppSelector(getAddresses);

export const useActivePoiId = () => useAppSelector(getActivePoiId);
export const useActiveGeofenceId = () => useAppSelector(getActiveGeofenceId);
export const useSelectedDriverIds = () => useAppSelector(getSelectedDriverIds);

export const useVisibleWidgetIds = () => useAppSelector(getVisibleWidgetIds);

export const useOverallGeoBookingState = () => useAppSelector(getOverallGeoBookingState);
